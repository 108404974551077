.mid-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: 'Roboto', sans-serif;
  background-color: rgb(243, 243, 225);

  .left-mid-section {
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    color: rgb(238, 5, 5);
    border: 1px solid rgb(238, 5, 5);
    text-align: center;

    h1 {
      font-size: 2rem;
    }

    h2 {
      margin: 0;
      margin-top: 20px;
      font-size: 2rem;
    }

    span {
      margin: 10px 25px !important;
      font-size: 1.1rem;
      text-align: center;
    }

    p {
      
      font-size: 1.1rem;
    }
  }

  .right-mid-section {
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    padding: 10px 5px;
    margin-right: 15px;

    ul {
      list-style-type: none;
      margin: 0;

      li {
        margin: 0;
      }
    }
    h3 {
      margin: 0;
    }
  }

  .temp-section {
    display: flex;
    max-width: 100%;
    width: 100%;
    border: 1px solid rgb(238, 5, 5);

    img {
      margin: 0 5px;
      width: 33%;
      height: auto;
    }
  }

  @media screen and (max-width: 975px) {
    font-size: 0.5rem;
    .temp-section {
      img {
        margin: 0 1px;
      }
    }
    ul {
      padding-left: 5px;
    }
  }
}
