.banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40vh;
  //   border: 0.5px solid green;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
  
}

@media screen and (max-width: 1205px) {
  .foodItem {
    display: none;
  }
}
